<template>
    <div>
        <div class="h-handle-bg">
            <div class="h-handle-button">
                <p class="h-return"><el-button type="text" size="small" @click="$parent.checkSaveStatus">返回</el-button> </p>
                <div class="h-b">
                    <el-button type="primary" size="small" @click="add" v-right-code="'Systemcode:Save'">新增</el-button>
                </div>
                <div class="h-b">
                    <el-button type="primary" size="small" @click="save" v-right-code="'Systemcode:Save'">保存</el-button>
                </div>
            </div>
        </div>

        <div class="form-list">
            <el-form :model="dataSource" ref="_basicForm" :rules="baseRule">
                <el-form-item>
                    <el-col :span="3" class="form-title">
                        <span style="color:red;">*</span> 编码标识：
                    </el-col>
                    <el-col :span="7">
                        <el-form-item prop="CodeId">
                            <el-input v-model="dataSource.CodeId" :maxlength="50"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="3" class="form-title">
                        <span style="color:red;">*</span> 编码名称：
                    </el-col>
                    <el-col :span="7">
                        <el-form-item prop="CodeName">
                            <el-input v-model="dataSource.CodeName" :maxlength="50"></el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="3" class="form-title">
                        <span style="color:red;">*</span> 编码类型：
                    </el-col>
                    <el-col :span="7">
                        <el-form-item prop="CodeType">
                            <DictionarySelect 
                                v-model="dataSource.CodeType" 
                                datType='Int' 
                                dicTypeCode="SystemCodeTypeCode">
                            </DictionarySelect>
                        </el-form-item>
                    </el-col>
                    <el-col :span="3" class="form-title">
                        <span style="color:red;">*</span> 业务类型：
                    </el-col>
                    <el-col :span="7">
                        <el-form-item prop="CodeBizType">
                            <DictionarySelect 
                                v-model="dataSource.CodeBizType" 
                                datType='Int' 
                                dicTypeCode="SystemCodeBizTypeCode">
                            </DictionarySelect>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="3" class="form-title">
                        <span style="color:red;">*</span> 编码规则：
                    </el-col>
                    <el-col :span="17">
                        <el-form-item prop="CodeRule">
                            <el-input v-model="dataSource.CodeRule" :maxlength="100"></el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="3" class="form-title">
                        备注：
                    </el-col>
                    <el-col :span="17">
                        <el-form-item prop="Remark">
                            <el-input type="textarea" :rows="5" v-model="dataSource.Remark"></el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            startEndTime:[],
            baseRule: {
                CodeId: [{
                    required: true,
                    message: '请输入编码标识',
                    trigger: 'blur'
                }],
                CodeName: [{
                    required: true,
                    message: '请选择编码类型',
                    trigger: 'change'
                }],
                CodeType: [{
                    required: true,
                    message: '请输入编码类型',
                    trigger: 'blur'
                }],
                CodeBizType: [{
                    required: true,
                    message: '请输入业务类型',
                    trigger: 'blur'
                }],
                CodeRule: [{
                    required: true,
                    message: '请输入编码规则',
                    trigger: 'blur'
                }],
            },
            controlConfig: {
                viewConfig:{
                    visible: false,
                    size: 'normal',
                    customClass: 'controlDialog'
                },
                dataConfig:{
                    dataType:1,
                }
            },
        }
    },
    props: {
        config: {
            isDetailDisplay: false,
        },
        dataSource: {}
    },
    watch: {
        dataSource: {
            handler(curVal, oldVal) {
                if(curVal && curVal.Id!=oldVal.Id){
                    if(curVal.StartTime && curVal.EndTime){
                        this.startEndTime = [curVal.StartTime,curVal.EndTime];
                    }
                }
            },
            deep: true
        },
    },
    methods: {
        add(){
            this.Event.$emit(this.config.moduleEvent.add);
        },
        save(){
            var _this = this
            _this.$refs['_basicForm'].validate((valid) => {
                _this.isPassValidate = valid
                if (valid) {
                    _this.$ajax.send(
                        'omsapi/systemcode/save',
                        'post',
                        _this.dataSource,
                        (data) => {
                            _this.$parent.syncDataSource(data.Result);
                            _this.Event.$emit(this.config.moduleEvent.initial)
                            _this.Utils.messageBox('保存成功.', 'success')
                        }
                    )
                } else {
                    return false
                }
            })
        }
    },
    components: {
    }
}
</script>